import React from "react";
import Helpers from "../../libs/Helpers";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Row,
  Modal,
  Image,
} from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import Loader from "../../libs/Loader"
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { AgGridReact } from "ag-grid-react";
import { AG_GRID_LOCALE_ES } from "@ag-grid-community/locale";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAward,
  faEye,
  faSave,
  faFileSignature,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

ModuleRegistry.registerModules([ClientSideRowModelModule]);
const localeText = AG_GRID_LOCALE_ES;

function DocsList(props) {
  const [documents, setDocuments] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [showModal, setShowModal] = React.useState(false);
  const [idDoc, setIdDoc] = React.useState();

  const gridRef = React.useRef();
  const navigation = new useNavigate();

  const token = localStorage.getItem("session");

  const removeDocument = () => {
    Helpers.postAuth("/documents/del", "POST", token, {
      id: idDoc,
    }).then((data, err) => {
      if (err) throw err;
      setShowModal(false);
      gridRef.current.api.refreshCells({ params: { force: true } });
      navigation(0);
    });
  };

  const removeLayouts = (e) => {
    e.preventDefault();
    Helpers.postAuth("/layouts/del", "POST", token, {}).then((data, err) => {
      if (err) throw err;
      navigation(0);
    });
  };

  const saveComponents = (e) => {
    e.preventDefault();
    Helpers.postAuth("/layouts/add", "POST", token, {
      logoprimario,
      logosecundario,
      layoutpresent,
      layoutmatri,
      layoutbaut,
    }).then((data, err) => {
      if (err) throw err;
      navigation(0);
    });
  };

  const cambiarVista = (id) => {
    props.Documento.setSelectId(id);
    props.Vista.setDocView(4);
  };

  const CustomButtonComponent = (props) => {
    return (
      <>
        <ButtonGroup>
          <Button
            variant="light"
            className="btn btn-sm"
            onClick={(e) => {
              e.preventDefault();
              cambiarVista(props.data._id);
            }}
          >
            <FontAwesomeIcon icon={faEye} className="fa-fw" />
          </Button>
          <Button
            variant="light"
            className="btn btn-sm"
            onClick={(e) => {
              e.preventDefault();
              setIdDoc(props.data._id);
              setShowModal(true);
            }}
          >
            <FontAwesomeIcon icon={faTrash} className="fa-fw" />
          </Button>
        </ButtonGroup>
      </>
    );
  };

  const columnDefs = [
    {
      headerName: "Fecha Creación",
      field: "date_record",
      filter: true,
      floatingFilter: false,
      flex: 1,
    },
    {
      headerName: "Tipo de Documento",
      field: "tipodoc",
      filter: true,
      floatingFilter: false,
      flex: 1,
    },
    {
      headerName: "Clase de Documento",
      field: "clase",
      filter: true,
      floatingFilter: false,
      flex: 1,
    },
    {
      headerName: "Número de Registro",
      field: "_id",
      filter: true,
      floatingFilter: false,
      flex: 2,
    },
    {
      field: "Acciones",
      cellRenderer: CustomButtonComponent,
      flex: 1,
    },
  ];

  React.useEffect(() => {
    Helpers.postAuth("/documents/all", "POST", token, {}).then((data) => {
      if (data) {
        setDocuments(data);
        Helpers.postAuth("/layouts/all", "POST", token, {}).then((data) => {
          setLayoutBaut(data[0]?.layoutbaut);
          setLayoutMatri(data[0]?.layoutmatri);
          setLayoutPresent(data[0]?.layoutpresent);
          setLogoprimario(data[0]?.logoprimario);
          setLogosecundario(data[0]?.logosecundario);
          setLoading(false);
        });
      }
    });
  }, [token]);

  const defaultColDef = {
    flex: 1,
  };

  const pagination = true;
  const paginationPageSize = 10;
  const paginationPageSizeSelector = [10, 20, 30];

  const [logoprimario, setLogoprimario] = React.useState();
  const [logosecundario, setLogosecundario] = React.useState();

  const [layoutpresent, setLayoutPresent] = React.useState();
  const [layoutbaut, setLayoutBaut] = React.useState();
  const [layoutmatri, setLayoutMatri] = React.useState();

  const fileRef0 = React.useRef();
  const fileRef1 = React.useRef();
  const fileRef2 = React.useRef();
  const fileRef3 = React.useRef();
  const fileRef4 = React.useRef();

  return (
    <div>
      {loading && (
        <Loader />
      )}
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        animation={true}
      >
        <Modal.Header>
          <Modal.Title>Eliminar Documento</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>¿Esta Seguro que Desea Eliminar este Documento?</p>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              setShowModal(false);
            }}
          >
            Cerrar
          </Button>
          <Button
            variant="danger"
            onClick={(e) => {
              e.preventDefault();
              removeDocument();
            }}
          >
            Si, Eliminar
          </Button>
        </Modal.Footer>
      </Modal>
      <Card className="shadow-sm border-0 bg-transparent">
        <Card.Header className="fw-bold">
          <Row>
            <Col>
              <span className="fw-bold d-flex justify-content-start align-items-center h-100">
                Gestión Documental
              </span>
            </Col>
            <Col className="text-end">
              <ButtonGroup>
                <Button
                  variant="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    props.Vista.setDocView(2);
                  }}
                >
                  <FontAwesomeIcon icon={faAward} className="fa-fw me-2" />
                  Nuevo Certificado
                </Button>
                <Button
                  variant="success"
                  onClick={(e) => {
                    e.preventDefault();
                    props.Vista.setDocView(3);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faFileSignature}
                    className="fa-fw me-2"
                  />
                  Nuevo Documento
                </Button>
                <Button
                  variant="warning"
                  onClick={(e) => {
                    e.preventDefault();
                    props.Vista.setDocView(3);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faFileSignature}
                    className="fa-fw me-2"
                  />
                  Nueva Acta
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-1">
          <div className="ag-theme-quartz" style={{ height: 250 }}>
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              rowData={documents}
              defaultColDef={defaultColDef}
              pagination={pagination}
              paginationPageSize={paginationPageSize}
              paginationPageSizeSelector={paginationPageSizeSelector}
              localeText={localeText}
            />
          </div>
        </Card.Body>
        <Card.Footer className="p-1"></Card.Footer>
      </Card>
      <Card className="mt-3 fw-bold border-0 shadow-sm bg-transparent">
        <Card.Header className="fw-bold">
          <Row>
            <Col>
              <span className="fw-bold d-flex justify-content-start align-items-center h-100">
                Componentes del Template
              </span>
            </Col>
            <Col className="text-end">
              <Button
                variant="danger"
                sm="true"
                onClick={(e) => removeLayouts(e)}
              >
                <FontAwesomeIcon icon={faTrash} className="fa-fw" />
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-2">
          <Row>
            <Col sm={12} md={5} className="mb-1 mt-1">
              <Card className="border-0 shadow-sm bg-transparent">
                <Card.Header className="fw-bold text-primary">
                  Logos para Documentos
                </Card.Header>
                <Card.Body style={{ height: "auto", padding: 5 }}>
                  <Row>
                    <Col sm={12} md={6} className="h-100">
                      <Card className="bg-transparent h-100 border-0">
                        <Card.Header className="p-1 border-0 bg-transparent">
                          <Button
                            className="w-100"
                            onClick={() => {
                              fileRef0.current.click();
                            }}
                          >
                            Logo Principal
                          </Button>
                        </Card.Header>
                        <Card.Body className="p-1">
                          <Image
                            src={logoprimario}
                            style={{
                              width: "100%",
                              height: 134,
                              objectFit: "cover",
                            }}
                          />
                          <input
                            type="file"
                            ref={fileRef0}
                            className="invisible"
                            accept="image/png"
                            value=""
                            onChange={(e) => {
                              e.preventDefault();
                              const reader0 = new FileReader();
                              let baseString0;
                              reader0.onloadend = function () {
                                baseString0 = reader0.result;
                                setLogoprimario(baseString0);
                              };
                              reader0.readAsDataURL(e.target.files[0]);
                            }}
                          />
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col sm={12} md={6} className="h-100">
                      <Card className="bg-transparent h-100 border-0">
                        <Card.Header className="p-1 border-0 bg-transparent">
                          <Button
                            variant="success"
                            className="w-100"
                            onClick={() => {
                              fileRef1.current.click();
                            }}
                          >
                            Logo Secundario
                          </Button>
                        </Card.Header>
                        <Card.Body className="p-1">
                          <Image
                            src={logosecundario}
                            style={{
                              width: "100%",
                              height: 134,
                              objectFit: "cover",
                            }}
                          />
                          <input
                            type="file"
                            ref={fileRef1}
                            className="invisible"
                            accept="image/png"
                            value=""
                            onChange={(e) => {
                              e.preventDefault();
                              const reader1 = new FileReader();
                              let baseString1;
                              reader1.onloadend = function () {
                                baseString1 = reader1.result;
                                setLogosecundario(baseString1);
                              };
                              reader1.readAsDataURL(e.target.files[0]);
                            }}
                          />
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={7} className="mb-1 mt-1">
              <Card className="border-0 shadow-sm bg-transparent">
                <Card.Header className="fw-bold text-primary">
                  Plantillas para los Certificados
                </Card.Header>
                <Card.Body style={{ padding: 5 }}>
                  <Row>
                    <Col sm={12} md={4} className="h-100">
                      <Card className="bg-transparent h-100 border-0">
                        <Card.Header className="p-1 border-0 bg-transparent">
                          <Button
                            className="w-100"
                            onClick={() => {
                              fileRef2.current.click();
                            }}
                          >
                            Presentación
                          </Button>
                        </Card.Header>
                        <Card.Body className="p-1">
                          <Image
                            src={layoutpresent}
                            style={{
                              width: "100%",
                              height: 134,
                              objectFit: "cover",
                            }}
                          />
                          <input
                            type="file"
                            ref={fileRef2}
                            className="invisible"
                            accept="image/svg"
                            value=""
                            onChange={(e) => {
                              e.preventDefault();
                              const reader2 = new FileReader();
                              let baseString2;
                              reader2.onloadend = function () {
                                baseString2 = reader2.result;
                                setLayoutPresent(baseString2);
                              };
                              reader2.readAsDataURL(e.target.files[0]);
                            }}
                          />
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col sm={12} md={4} className="h-100">
                      <Card className="bg-transparent h-100 border-0">
                        <Card.Header className="p-1 border-0 bg-transparent">
                          <Button
                            variant="success"
                            className="w-100"
                            onClick={() => {
                              fileRef3.current.click();
                            }}
                          >
                            Bautismo
                          </Button>
                        </Card.Header>
                        <Card.Body className="p-1">
                          <Image
                            src={layoutbaut}
                            style={{
                              width: "100%",
                              height: 134,
                              objectFit: "cover",
                            }}
                          />
                          <input
                            type="file"
                            ref={fileRef3}
                            className="invisible"
                            accept="image/svg"
                            value=""
                            onChange={(e) => {
                              e.preventDefault();
                              const reader3 = new FileReader();
                              let baseString3;
                              reader3.onloadend = function () {
                                baseString3 = reader3.result;
                                setLayoutBaut(baseString3);
                              };
                              reader3.readAsDataURL(e.target.files[0]);
                            }}
                          />
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col sm={12} md={4} className="h-100">
                      <Card className="bg-transparent h-100 border-0">
                        <Card.Header className="p-1 border-0 bg-transparent">
                          <Button
                            variant="info"
                            className="w-100"
                            onClick={() => {
                              fileRef4.current.click();
                            }}
                          >
                            Matrimonio
                          </Button>
                        </Card.Header>
                        <Card.Body className="p-1">
                          <Image
                            src={layoutmatri}
                            style={{
                              width: "100%",
                              height: 134,
                              objectFit: "cover",
                            }}
                          />
                          <input
                            type="file"
                            ref={fileRef4}
                            className="invisible"
                            accept="image/svg"
                            value=""
                            onChange={(e) => {
                              e.preventDefault();
                              const reader4 = new FileReader();
                              let baseString4;
                              reader4.onloadend = function () {
                                baseString4 = reader4.result;
                                setLayoutMatri(baseString4);
                              };
                              reader4.readAsDataURL(e.target.files[0]);
                            }}
                          />
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="p-1">
          <Button className="w-100" onClick={(e) => saveComponents(e)}>
            <FontAwesomeIcon icon={faSave} className="fa-fw me-2" />
            Guardar Componentes
          </Button>
        </Card.Footer>
      </Card>
    </div>
  );
}

export default DocsList;
