import React from "react";
import {
  Card,
  Button,
  Row,
  Col,
  InputGroup,
  Form,
  Collapse,
  Alert,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faRobot } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import Helpers from "../../libs/Helpers";

function BranchesAdd(props) {
  const token = localStorage.getItem("session");

  const [nombre_sucursal, setNombre_Sucursal] = React.useState();
  const [pastor, setPastor] = React.useState();
  const [direccion, setDireccion] = React.useState();
  const [telefono, setTelefono] = React.useState();
  const [tipo_sucursal, setTipoSucursal] = React.useState();
  const [latitud, setLatitud] = React.useState();
  const [longitud, setLongitud] = React.useState();

  const [alertOpen, setAlertOpen] = React.useState(false);
  const [textAlert, setTextAlert] = React.useState();
  const [variantAlert, setVariantAlert] = React.useState("warning");

  const showAlert = (texto) => {
    setTextAlert(texto);
    setAlertOpen(true);
    setTimeout(() => {
      setAlertOpen(false);
    }, 3000);
  };

  const addSucursal = () => {
    if (
      !nombre_sucursal ||
      !pastor ||
      !direccion ||
      !telefono ||
      !tipo_sucursal ||
      !latitud ||
      !longitud
    ) {
      setVariantAlert("warning");
      showAlert("Debes Ingresar todos los Datos Requeridos");
    } else {
      Helpers.postAuth("/branches/add", "POST", token, {
        nombre_sucursal,
        pastor,
        direccion,
        telefono,
        tipo_sucursal,
        latitud,
        longitud,
      }).then((data, err) => {
        if (err) throw err;
        setVariantAlert("info");
        showAlert("Sucursal Creada con Exito");
        setTimeout(() => {
          props.Vista.setBranchView(0);
        }, 3000);
      });
    }
  };

  const getBot = async () => {
    if(direccion){
    const location = await Helpers.getLocation(direccion);
    setLatitud(location.lat);
    setLongitud(location.lng);
    } else {
      setVariantAlert("warning");
      showAlert("Debes Ingresar la Dirección");
    }
  };

  return (
    <div>
      <Collapse
        in={alertOpen}
        dimension={"height"}
        timeout={300}
        className="fixed-top p-3 m-2 rounded"
      >
        <Alert
          variant={variantAlert}
          className="fw-bold text-center"
          style={{
            display: alertOpen ? "block" : "none",
          }}
          id="example-collapse-text"
        >
          {textAlert}
        </Alert>
      </Collapse>
      <Card className="shadow-sm border-0 bg-transparent">
        <Card.Header>
          <Button
            onClick={(e) => {
              e.preventDefault();
              props.Vista.setBranchView(0);
            }}
            variant="transparent"
            className="fw-bold"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="fa-fw me-2" />
            Agregar Nueva Sucursal
          </Button>
        </Card.Header>
        <Card.Body>
          <form onSubmit={(e) => e.preventDefault()}>
            <Row>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeft">
                    Nombre
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    required
                    value={nombre_sucursal}
                    onChange={(e) => {
                      setNombre_Sucursal(e.target.value);
                    }}
                  />
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeft">
                    Pastor
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    required
                    value={pastor}
                    onChange={(e) => {
                      setPastor(e.target.value);
                    }}
                  />
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeft">
                    Dirección
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    required
                    value={direccion}
                    onChange={(e) => {
                      setDireccion(e.target.value);
                    }}
                  />
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeft">
                    Teléfono
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    required
                    value={telefono}
                    onChange={(e) => {
                      setTelefono(e.target.value);
                    }}
                  />
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeft">
                    Tipo
                  </InputGroup.Text>
                  <Form.Select
                    required
                    value={tipo_sucursal}
                    onChange={(e) => {
                      setTipoSucursal(e.target.value);
                    }}
                  >
                    <option value="Local">Local</option>
                    <option value="Nacional">Nacional</option>
                    <option value="Fuera del País">Fuera del País</option>
                    <option value="Comité Nacional">Comité Nacional</option>
                  </Form.Select>
                </InputGroup>
              </Col>
            </Row>
            <Card className="mt-2 bg-transparent shadow-sm">
              <Card.Header
                className="fw-bold"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="fw-bold">Geo Referenciación</div>
                <div>
                  <Button
                    className="btn btn-sm shadow-sm"
                    variant="light"
                    onClick={async (e) => {
                      e.preventDefault();
                      getBot();
                    }}
                  >
                    <FontAwesomeIcon icon={faRobot} className="fa-fw me-2" />
                    GeoBot
                  </Button>
                </div>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col sm={12} md={6} className="mt-2">
                    <InputGroup>
                      <InputGroup.Text id="basic-addon1" className="colLeft">
                        Latitud
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        required
                        value={latitud}
                        onChange={(e) => {
                          setLatitud(e.target.value);
                        }}
                        readOnly
                      />
                    </InputGroup>
                  </Col>
                  <Col sm={12} md={6} className="mt-2">
                    <InputGroup>
                      <InputGroup.Text id="basic-addon1" className="colLeft">
                        Longitud
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        required
                        value={longitud}
                        onChange={(e) => {
                          setLongitud(e.target.value);
                        }}
                        readOnly
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Row>
              <Col sm={12} className="mt-4">
                <Button
                  className="w-100"
                  onClick={(e) => {
                    e.preventDefault();
                    addSucursal();
                  }}
                >
                  <FontAwesomeIcon icon={faSave} className="fa-fw me-2" />{" "}
                  Guardar Sucursal
                </Button>
              </Col>
            </Row>
          </form>
        </Card.Body>
        <Card.Footer></Card.Footer>
      </Card>
    </div>
  );
}

export default BranchesAdd;
