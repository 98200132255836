import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './screens/Home';
import MainUsers from './screens/Admin/Users';
import MainLicences from './screens/Admin/Licences';
import MainOficials from './screens/Admin/Oficials';
import MainRoles from './screens/Admin/Roles';
import ReportEclesiastics from './screens/Reports/Eclesiactics';
import ReportFinancial from './screens/Reports/Financial';
import Accounting from './screens/Financial/Accounting';
import Tithes from './screens/Financial/Tithes';
import Credits from './screens/Financial/Credits';
import Opening from './screens/Financial/Opening';
import Actives from './screens/Inventory/Actives';
import Branches from './screens/Congregational/Branches';
import Members from './screens/Congregational/Members';
import Friends from './screens/Congregational/Friends';
import Profile from './screens/Profile';
import Forgot from './screens/Forgot';
import Familys from './screens/Congregational/Familys';
import Docs from './screens/Documental/Docs';

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/congregational/branches' element={<Branches />} />
        <Route path='/congregational/members' element={<Members />} />
        <Route path='/congregational/familys' element={<Familys />} />
        <Route path='/congregational/friends' element={<Friends />} />
        <Route path='/congregational/documental' element={<Docs />} />
        <Route path='/inventory/actives' element={<Actives />} />
        <Route path='/financial/accounting' element={<Accounting />} />
        <Route path='/financial/tithes' element={<Tithes />} />
        <Route path='/financial/credits' element={<Credits />} />
        <Route path='/financial/opening' element={<Opening />} />
        <Route path='/reports/eclesiastics' element={<ReportEclesiastics />} />
        <Route path='/reports/financial' element={<ReportFinancial />} />
        <Route path='/admin/users' element={<MainUsers />} />
        <Route path='/admin/licences' element={<MainLicences />} />
        <Route path='/admin/oficials' element={<MainOficials />} />
        <Route path='/admin/roles' element={<MainRoles />} />
        <Route path='/forgot/:token?' element={<Forgot />} />
      </Routes>
    </div>
  );
}

export default App;
