import { setDefaults, fromAddress } from "react-geocode"; 

const API = "https://ml0.imesh.app/api";
//const API = "http://192.168.0.28:5500/api"
 

const postAuth = async (endpoint, method, token, body) => {
  try {
    return await fetch(API + endpoint, {
      method,
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    }).then((response) => response.json());
  } catch (err) {
    console.info("Error:", err);
  }
};
const queryNoAuth = async (endpoint, method, body) => {
  try {
    return await fetch(API + endpoint, {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((response) => response.json());
  } catch {
    console.info("Error");
  }
};

const verifyAPI = async () => {
  try {
    return await fetch(API + "/admins/state", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.json())
      .then(()=>{
        return true
      })

  } catch {
    return false;
  }
};

const sendAddress = (namesuc, msg, numtel) => {
  const sucu = `*IPUNSOFT: [${namesuc}]*`;
  const encotext = encodeURI(sucu + msg);
  window.open(`https://wa.me/${numtel}?text=${encotext}`, '_blank');
}

const getLocation = (address) => {
  setDefaults({
    key: "AIzaSyCtQLkmd1Of88ecJgjnOpwcVY3GF37kWh4",
    language: "es"
  });
  return new Promise((resolve)=>{
    fromAddress(address)
  .then(({ results }) => {
    const { lat, lng } = results[0].geometry.location;
    resolve({ lat, lng });
  })
  .catch(console.error); 
  })
  
}

const Capitalize = (str) => {
  const words = str?.split(" ");
  const palabras = [];
  words?.map((word) => (
    palabras?.push(word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() + " ")
  ))
  return palabras.join([' ']).trim()
}

// eslint-disable-next-line
export default { queryNoAuth, postAuth, verifyAPI, sendAddress, getLocation, Capitalize };
